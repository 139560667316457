body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex {
    display: flex;
}

.mb-20 {
    margin-bottom: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.ant-menu-item-selected {
    border-right: none !important;
}

.global-btn {
    background: #006641;
}

.ant-input-search-button {
    background: #006641;
}

.ant-input-search-button:hover {
    background: #006641 !important;
    color: #ffffff !important;
}

.global-btn:hover {
    background: #006641 !important;
    color: #ffffff !important;
}

.global-btn:disabled {
    background: none !important;
    color: rgb(229, 225, 225) !important;
}

.ant-pagination-item-active {
    background: #006641 !important;
}

.ant-popconfirm-buttons .ant-btn-primary {
    background: #006641;
}

.ant-popconfirm-buttons .ant-btn-primary:hover {
    background: #006641 !important;
}

.ant-picker-ok .ant-btn {
    background: #006641 !important;
    color: #ffffff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #006641 !important;
}

.ant-tabs-tab:hover {
    color: #006641 !important;
}

.ant-tabs-ink-bar {
    background: #006641 !important;
}

.ant-spin-dot-item {
    background-color: #006641 !important;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #006641 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #006641 !important;
    border-color: #006641;
}
.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #006641 !important;
    border-color: #006641 !important;
}
.ant-checkbox-checked:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #006641 !important;
    border-color: #006641 !important;
}
.ant-checkbox-checked:after {
    border-color: #006641 !important;
}
.ant-checkbox:hover::after {
    border-color: #006641 !important;
}
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #006641 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #006641 !important;
}

.justifyFlexEnd {
    justify-content: flex-end;
}

.ant-menu-submenu-arrow {
    color: #006641 !important;
}

.verify-later .ant-modal-content {
    border-radius: 7px !important;
    padding: 40px 30px 30px 30px;
}

.verify-later .ant-modal-title {
    color: #111827 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.82px;
}

.attached-documents-modal .ant-modal-content {
    border-radius: 7px !important;
    /* padding: 40px 30px 30px 30px; */
}

.attached-documents-modal .ant-modal-title {
    color: #111827 !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.82px;
}

.ant-modal-content .ant-modal-close-icon {
    color: #717374 !important;
}
